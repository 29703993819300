/*
  ____                _                                         _
 | __ )   __ _   ___ | | __ __ _  _ __  ___   _   _  _ __    __| |
 |  _ \  / _` | / __|| |/ // _` || '__|/ _ \ | | | || '_ \  / _` |
 | |_) || (_| || (__ |   <| (_| || |  | (_) || |_| || | | || (_| |
 |____/  \__,_| \___||_|\_\\__, ||_|   \___/  \__,_||_| |_| \__,_|
                           |___/
*/

.HomeGridLayer {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50px auto 60% 50px;
    grid-template-rows: 30px 10% 12% 35% 35% auto;
    gap: 1px 1px;
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". SubTitleText SubTitleText ." ". . . .";
    z-index: 5;
    padding-bottom: 100px;
}

.HomeAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: white;
    opacity: .3;
    z-index: 1;
}

.HomeInk {
    position:absolute;
    height:200vw;
    width:200vw;
    background: white;
    top: 0;
    left: 50%;
    transform:translateX(-50%);
    border-radius:40%;
    animation: fill 4s ease-in;
    z-index: 3;
}


@keyframes fill {
    from {
        top:125vh;
        /*opacity: .8;*/
        transform:translateX(-50%) rotate(0deg);
    }
    to {
        top:-25vh;
        /*opacity: .99;*/
        transform:translateX(-50%) rotate(360deg);
    }
}

/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.HomeGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(3, 49, 87, 0.85);
}

.HomeGridLayer > .PageTitle {
    height: 63%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.HomeGridLayer > .PageTitle > #invictus_letters {
    fill: #054E8A;
}


.HomeGridLayer > .PageTitle:hover #invictus_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #054E8A;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#invictus_letters path:nth-child(1) {
    stroke-dasharray: 300;
    stroke-dashoffset: 416;
}

#invictus_letters path:nth-child(2) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1262;
}

#invictus_letters path:nth-child(3) {
    stroke-dasharray: 300;
    stroke-dashoffset: 865;
}

#invictus_letters path:nth-child(4) {
    stroke-dasharray: 300;
    stroke-dashoffset: 416;
}

#invictus_letters path:nth-child(5) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#invictus_letters path:nth-child(6) {
    stroke-dasharray: 300;
    stroke-dashoffset: 708;
}

#invictus_letters path:nth-child(7) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#invictus_letters path:nth-child(8) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1118;
}

#invictus_letters path:nth-child(9) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1354;
}

#invictus_letters path:nth-child(10) {
    stroke-dasharray: 300;
    stroke-dashoffset: 970;
}

#invictus_letters path:nth-child(11) {
    stroke-dasharray: 300;
    stroke-dashoffset: 416;
}

@media only screen and (max-width: 800px) {
    /*50% 10% 10% 5% 20% auto*/
    .HomeGridLayer {
        width: 100vw;
        grid-template-columns: 6% 88% 6%;
        grid-template-rows: 50% 10% 10% 5% 20% auto;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ."  ". . ." ". NavBar ." ". . ."
    }

    .HomeGridLayer > .PageTitle {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .HomeGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2vh;
    }

    .HomeInk {
        background: #0e1340;
        height:200vw;
        width:200vw;
        border-radius:25%;
        animation: fill 6s ease-in;
    }

    @keyframes fill {
        from {
            top:75vw;
            /*opacity: .8;*/
            transform:translateX(-50%) rotate(0deg);
        }
        to {
            top:-25vh;
            /*opacity: .99;*/
            transform:translateX(-50%) rotate(360deg);
        }
    }

    .HomeGridLayer > .PageSubTitle br {
        display: none;
    }
}

@media only screen and (max-width: 550px) {
    .HomeGridLayer {
        width: 100vw;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 30% 9% 15% 5% 20% auto;*/
        grid-template-rows: 40% 10% 20% 5% 20% auto;
        gap: 1px 1px;
        grid-template-areas: ". . ." "TitleText TitleText TitleText" ". SubTitleText ." ". . ." ". NavBar ." ". . ."
    }

    .HomeGridLayer > .PageSubTitle br {
        display: none;
    }
}